import React, { Suspense, useContext } from 'react';

import { useParams } from 'react-router-dom';

import { ISearchConfig } from '../../routes/Search/Search';
import { IFrontpageConf } from '../../routes/Frontpage/Frontpage';
import { ICategoryConfig } from '../../routes/Category/Category';

import { IUpcomingConfig } from '../../routes/Upcoming/Upcoming';
import { IRouterParams } from '../../types';
import { IPlayerConfig } from '../../routes/Player/Player';
import { GlobalContext } from '../../context/GlobalContext';
import { ShareIcon } from '../ShareIcons/ShareIcons';

const Search = React.lazy(() => import('../../routes/Search/Search'));
const Player = React.lazy(() => import('../../routes/Player/Player'));
const Frontpage = React.lazy(() => import('../../routes/Frontpage/Frontpage'));
const Category = React.lazy(() => import('../../routes/Category/Category'));
const Upcoming = React.lazy(() => import('../../routes/Upcoming/Upcoming'));
const Login = React.lazy(() => import('../../routes/Login/Login'));

export interface IMainStyle {
    backgroundColor?: string,
    textColor?: string,
    font?: {
        name: string,
        googleFontQueryString: string
    }
  }

export interface IRouterConf {
    search: ISearchConfig,
    player?: IPlayerConfig,
    category: ICategoryConfig,
    frontpage: IFrontpageConf,
    upcoming?: IUpcomingConfig
}

interface IRouterProps {
    config: IRouterConf,
    shareIcons: ShareIcon[] | undefined
}

export default function CustomRouter({ config, shareIcons }: IRouterProps) {
    const ctx = useContext(GlobalContext);

    let { path } = useParams<IRouterParams>();

    if (path === undefined) {
        path = 'frontpage';
    }

    let strPath: string = (path as string).toLowerCase();

    let paths: { [key: string]: JSX.Element; } = {
        'search': <Suspense fallback=""><Search config={config.search} /></Suspense>,
        'player': <Suspense fallback=""><Player config={config.player} shareIcons={shareIcons} /></Suspense>,
        'category': <Suspense fallback=""><Category config={config.category} /></Suspense>,
        'frontpage': <Suspense fallback=""><Frontpage config={config.frontpage} /></Suspense>,
        'upcoming': <Suspense fallback=""><Upcoming config={config.upcoming} /></Suspense>,
        'login': <Suspense fallback=""><Login /></Suspense>
    }

    // We are not authenticated with a session
    if (ctx.customerSettings.showInternalContent && !ctx.customerSettings.showExternalContent && ctx.auth === undefined) {
        // User enforces IDP logins
        if ((ctx.customerSettings.loginUrl ?? "").length > 0) {
            strPath = 'login';
        }
    }

    return (
        <>
            {paths[strPath] !== undefined ? paths[strPath] : 'Fail to find component'}
        </>
    );
}
