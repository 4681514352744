import React, { useContext, useEffect, useRef, useState } from 'react';
import { Popover, Overlay } from 'react-bootstrap';
import { GlobalContext } from '../../context/GlobalContext';

interface ShareIconsProps {
    wrapperClassName: string,
    textColor: string,
    backgroundColor: string,
    icons: ShareIcon[] | undefined
}

export enum ShareIcon {
    CopyLink = 'copyLink',
    Facebook = 'facebook',
    Rss = 'rss',
    Twitter = 'twitter'
}

const ShareIcons = ({ wrapperClassName, textColor, backgroundColor, icons }: ShareIconsProps) => {
    const ctx = useContext(GlobalContext);

    const copyToClipboard = () => {
        const textArea = document.createElement('textarea');
        textArea.classList.add("sr-only");
        textArea.setAttribute("aria-hidden", "true");

        textArea.value = window.location.href;
        document.body.append(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    const copyButton = useRef(null);
    const [showCopySucces, setShowCopySuccess] = useState(false);

    useEffect(() => {
        if (showCopySucces) {
            const hideCopySucces = setTimeout(() => {
                setShowCopySuccess(false);
            }, 2500);

            return () => {
                clearTimeout(hideCopySucces);
            };
        }
    }, [showCopySucces]);

    return (
        <div className={wrapperClassName}>
            {icons !== undefined && icons.includes(ShareIcon.CopyLink) ?
                <>
                    <button ref={copyButton} className="p-0 m-0 border-0" style={{ backgroundColor: 'transparent' }} aria-label={ctx.translate('COPY-LINK')} onClick={() => { setShowCopySuccess(true); copyToClipboard(); }}>
                        <i className="fa fa-share-alt-square fa-1-5x" style={{ color: backgroundColor }}></i>
                    </button>
                    <Overlay show={showCopySucces} target={copyButton.current} placement="bottom">
                        <Popover id={"link-copy-successful"}>
                            <Popover.Content>
                                <i className="fa fa-check-circle text-success"></i> <span>{ctx.translate('LINK-COPIED')}</span>
                            </Popover.Content>
                        </Popover>
                    </Overlay>
                    
                </>
                : null
            }
            {icons !== undefined && icons.includes(ShareIcon.Facebook) ?
                <a
                    className="ml-2"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`${ctx.translate("SHARE-ON")} Facebook.`}
                >
                    <i className="fab fa-facebook-square fa-1-5x" style={{ color: backgroundColor }}></i>
                </a>
                : null
            }
            {icons !== undefined && icons.includes(ShareIcon.Twitter) ?
                <a
                    className='ml-2'
                    href={`https://twitter.com/intent/tweet?text=${window.location.href}&url=${window.location.href}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label={`${ctx.translate("SHARE-ON")} Twitter.`}
                >
                    <i className='fab fa-twitter-square fa-1-5x' style={{ color: backgroundColor }}></i>
                </a>
                : null
            }
            {icons !== undefined && icons.includes(ShareIcon.Rss) && ctx.rssEnabled ?
                <a className="ml-2" href={`/rss/explore/${ctx.producer}`} target="_blank" rel="noopener noreferrer" aria-label={`RSS`}>
                    <i className="fa fa-rss-square fa-1-5x" style={{ color: backgroundColor }}></i>
                </a>
                : null
            }
        </div>
    )
}

export default ShareIcons;
