import React, { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';

interface CookieConsentProps {
    consentToCookies: Function
}

export const CookieConsent = ({ consentToCookies }: CookieConsentProps) => {
    const ctx = useContext(GlobalContext);
    return (
        <div className="fixed-top border-dark border-bottom bg-light text-dark text-center p-3">
            <div className="row">
                <div className="col-12 col-lg-8 offset-lg-2">
                    <p>
                        {ctx.translate('COOKIE-CONSENT-1')} <br />
                        {ctx.translate('COOKIE-CONSENT-2')} <br />
                        {ctx.translate('READ-MORE-ABOUT-COOKIES')} <a href="https://play.quickchannel.com/qc/doc/cookies/" rel="noopener noreferrer" target="_blank">{ctx.translate('HERE')}</a>.
                    </p>
                </div>
                <div className="col-12 justify-content-around d-flex">
                    <button className="btn btn-warning m-1" onClick={() => consentToCookies()}><i className="fa fa-check"></i> {ctx.translate('COOKIES-APPROVE')}</button>
                    <button className="btn btn-outline-dark m-1" onClick={() => ctx.revokeCookies()}><i className="fa fa-times"></i> {ctx.translate('COOKIES-DENY')}</button>
                </div>
            </div>
        </div>
    );
}