import React from 'react';
import ReactDOM from 'react-dom';
import App from './comp/App/App';

let conf, producer, rssEnabled, customerSettings;

try {
    // @ts-ignore: Unhandled Rejection (ReferenceError)
    conf = g_config;
} catch (err) {
    conf = null;
}

try {
    // @ts-ignore: Unhandled Rejection (ReferenceError)
    producer = g_producer;
} catch (err) {
    producer = undefined;
    producer = 'phcicuh';
}

try {
    // @ts-ignore: Unhandled Rejection (ReferenceError)
    rssEnabled = g_rssenabled;
} catch (err) {
    rssEnabled = true;
}

try {
    // @ts-ignore: Unhandled Rejection (ReferenceError)
    customerSettings = g_customer_settings;
} catch (err) {
    customerSettings = {
        "showInternalContent": true,
        "showExternalContent": true,
        "loginUrl": "https://dev-srv1.quickchannel.com/auth/auth?producer=phcicuh&redirect=Explore",
        "logoutUrl": "https://dev-srv1.quickchannel.com/auth/signout?producer=phcicuh&redirect=Explore"
    };
}


ReactDOM.render(
    <React.StrictMode>
        <App producer={producer} conf={conf} rssEnabled={rssEnabled} customerSettings={customerSettings} />
    </React.StrictMode>,
    document.getElementById('root')
);