import React, { useEffect, useState } from 'react';
import './App.css';

import CustomRouter, { IMainStyle, IRouterConf } from '../CustomRouter/CustomRouter';
import Header, { IHeaderConf } from '../Header/Header';
import Footer, { IFooterConf } from '../Footer/Footer';

import {
    BrowserRouter,
    Switch,
    Route
} from 'react-router-dom';

import API, { ICategory } from '../ApiWrapper/ApiWrapper';

import { GlobalContext, IAuth, ICustomerSettings } from '../../context/GlobalContext';
import { IFilmcardStyle } from '../FilmCard/FilmCard';
import { useTranslation } from '@qc/localization-react';
import { defaultConfig } from '../../functions/Defaults';

import English from '../../submodules/localization/quickchannel-play/en.json';
import Swedish from '../../submodules/localization/quickchannel-play/sv.json';
import Finnish from '../../submodules/localization/quickchannel-play/fi.json';
import Estonian from '../../submodules/localization/quickchannel-play/et.json';

import Storage from '../../functions/Storage';
import { CookieConsent } from '../CookieConsent/CookieConsent';
type ExploreLanguage = typeof import('../../submodules/localization/quickchannel-play/en.json');

interface AppProps {
    producer: string,
    conf?: Config | null,
    rssEnabled: boolean,
    customerSettings: ICustomerSettings
}

export interface Config {
    header: IHeaderConf,
    main: IRouterConf,
    footer?: IFooterConf,
    mainStyle?: IMainStyle,
    cardStyle?: IFilmcardStyle
}

function App({ conf, producer, rssEnabled, customerSettings }: AppProps) {
    const { translateKey: translate } = useTranslation<ExploreLanguage>(English, { 'sv': Swedish, 'fi': Finnish, 'et': Estonian });
    const storage = new Storage();

    const [categories, setCategories] = useState<ICategory[]>([]);

    const [auth] = useState<IAuth | undefined>(() => {
        let sessionId = storage.get("SESSIONID");
        let producerId = storage.get("PRODUCER_ID");
        let auth: IAuth | undefined = undefined;
        if (sessionId !== undefined && producerId !== undefined && sessionId !== '' && producerId !== '') {
            auth = {
                sessionId: sessionId,
                producerId: producerId
            }
        }

        return auth;
    });

    const [useConf] = useState(() => {
        if (conf === undefined || conf === null) {
            return defaultConfig;
        } else {
            // Backwards compatibility
            // Setting for icons is moved to footer, but was originally in header.
            let newConf = conf;
            if (newConf.footer !== undefined && newConf.footer.content?.icons === undefined) {
                newConf.footer.content = {
                    icons: newConf.header.content.icons
                }
            }

            return newConf;
        }
    });

    useEffect(() => {
        API.getCategories(producer, auth).then(cat => {
            setCategories(cat);
        });
    }, [producer, auth]);

    let consent = storage.get("cookies_consent");
    const [cookiesConsented, setCookiesConsented] = useState((consent === undefined) ? undefined : (consent === "true"));

    useEffect(() => {
        if (useConf.mainStyle?.font !== undefined) {
            console.log("Loading custom font for layout");
            let preconfiguredFonts = `'dm-sans', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`;
            let styleString =
                `@import url('https://fonts.googleapis.com/css2?${useConf.mainStyle.font.googleFontQueryString}');
body, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .btn, span {
    font-family: '${useConf.mainStyle.font.name}', ${preconfiguredFonts};
}`;
            const style = document.createElement('style');
            style.textContent = styleString;
            document.head.append(style);
        }
    }, [useConf]);

    let baseUrl = (window.location.href.includes('explore') ? `/explore/${producer}` : '');

    let allowCookies = () => {
        storage.setConsent(true);
        storage.set("cookies_consent", "true");
        setCookiesConsented(true);
    }

    let denyCookies = () => {
        setCookiesConsented(false);
        storage.clearStorage();
    }


    return (
        <GlobalContext.Provider value={{
            translate: translate,
            producer: producer,
            baseUrl: baseUrl,
            mainStyle: useConf.mainStyle,
            cardStyle: useConf.cardStyle,
            cookiesAllowed: (cookiesConsented === true),
            rssEnabled: rssEnabled,
            revokeCookies: denyCookies,
            customerSettings: customerSettings,
            auth: auth
        }}>
            {cookiesConsented === undefined ? <CookieConsent consentToCookies={allowCookies} /> : undefined}
            <BrowserRouter>
                <Switch>
                    <Route path="/explore/:producer_id?/:path?/:param1?/:param2?">
                        <Header config={useConf.header} categories={categories} />
                        <div className="main-wrapper" style={{ backgroundColor: useConf.mainStyle?.backgroundColor ?? 'white', color: useConf.mainStyle?.textColor ?? 'black' }}>
                            <div className="main-content container-fluid" role="main">
                                <CustomRouter config={useConf.main} shareIcons={useConf.footer?.content?.icons} />
                            </div>
                        </div>
                        <Footer config={useConf.footer} />
                    </Route>
                    <Route path="/:path?/:param1?/:param2?">
                        <Header config={useConf.header} categories={categories} />
                        <div className="main-wrapper" style={{ backgroundColor: useConf.mainStyle?.backgroundColor ?? 'white', color: useConf.mainStyle?.textColor ?? 'black' }}>
                            <div className="main-content container-fluid" role="main">
                                <CustomRouter config={useConf.main} shareIcons={useConf.footer?.content?.icons} />
                            </div>
                        </div>
                        <Footer config={useConf.footer} />
                    </Route>
                    <Route path="/">
                        <h1>Fail</h1>
                    </Route>
                </Switch>
            </BrowserRouter>
        </GlobalContext.Provider>
    );
}

export default App;