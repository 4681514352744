import { Config } from "../comp/App/App";
import { ICategoryHighlightComponent } from "../comp/CategoryHighlight/CategoryHighlight";
import { IMainStyle, IRouterConf } from "../comp/CustomRouter/CustomRouter";
import { IFilmcardStyle } from "../comp/FilmCard/FilmCard";
import { IFilmlistComponent } from "../comp/Filmlist/Filmlist";
import { IFooterConf } from "../comp/Footer/Footer";
import { IHeaderConf } from "../comp/Header/Header";
import { IJumbotronComponent } from "../comp/Jumbotron/Jumbotron";
import { ShareIcon } from "../comp/ShareIcons/ShareIcons";
import { IPlayerConfig } from "../routes/Player/Player";

let defHeaderConf: IHeaderConf = {
    content: {
      logo: {
        src: 'https://secure.quickchannel.com/qc/backend/assets/images/QC_logo_black.png', //'https://www.quickchannel.com/hs-fs/hubfs/qc-logo-white.png?width=468&name=qc-logo-white.png',
        height: '40px',
        alt: 'Quickchannels logo'
      }
    },
    style: {
      backgroundColor: 'white',
      textColor: '#2e2e2e'
    }
};
  
let defFooterConf: IFooterConf = {
    content: {
      icons: [
        ShareIcon.CopyLink,
        ShareIcon.Facebook,
        ShareIcon.Rss
      ]
    },
    style: {
        backgroundColor: '#2e2e2e',
        textColor: 'white'
    },
    enableRevokeCookieConsent: true
}

let frontpageJumbotron: IJumbotronComponent = {
    type: 'jumbotron',
    config: {
        style: {
        },
        hideLivePrograms: false
    }
}

let categoryHighlightOvrigt: ICategoryHighlightComponent = {
    type: 'categoryHighlight',
    config: {
        category: 'övrigt',
        style: {
            textColor: ''
        }
    }
}

let categoryHighlightInstruktionsfilmer: ICategoryHighlightComponent = {
    type: 'categoryHighlight',
    config: {
        category: 'fullmäktige',
        style: {
            textColor: 'red'
        }
    }
}

let categoryHighlightNyheter: ICategoryHighlightComponent = {
    type: 'categoryHighlight',
    config: {
        category: 'asdfsdgfder'
    }
}

let categoryFilmlist: IFilmlistComponent = {
    type: 'filmlist',
    config: {
        mode: 'list',
        resultsPerPage: 5
    }
}

let searchFilmlist: IFilmlistComponent = {
    type: 'filmlist',
    config: {
        mode: 'list',
        resultsPerPage: 7
    }
}

let defMainStyle: IMainStyle = {
    backgroundColor: 'white',
    textColor: '#202833'

}

let defCardStyle: IFilmcardStyle = {
    highlighted: {
        backgroundColor: 'white',
        textColor: '#202833'
    }
}

let playerConfig: IPlayerConfig = {
    style: {
        playBtnBackgroundColor: 'white',
        playBtnTextColor: '#276267',
        playBtnBorderColor: '#276267',
        playBtnBorderWidth: '3px',
        jumbotronBackgroundColor: '',
        jumbotronTextColor: ''
    }
}

let defRouterConf: IRouterConf = {
    frontpage: {
        content: [
            frontpageJumbotron,
            categoryHighlightNyheter,
            categoryHighlightOvrigt,
            categoryHighlightInstruktionsfilmer
        ]
    },
    category: {
        content: [
            categoryFilmlist
        ]
    },
    search: {
        content: [
            searchFilmlist,
        ],
        style: {
            textColor: "orange"
        },
    },
    upcoming: {
        filmlist: {
            mode: 'grid',
            resultsPerPage: 10
        }
    },
    player: playerConfig
}

export const defaultConfig: Config = {
    header: defHeaderConf,
    main: defRouterConf,
    mainStyle: defMainStyle,
    cardStyle: defCardStyle,
    footer: defFooterConf
}