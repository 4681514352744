import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../context/GlobalContext';

interface SearchProps {
    style?: {
        backgroundColor?: string,
        textColor?: string
    },
    searchCallback: () => void
}

const Search = ({ searchCallback, style }: SearchProps) => {
    let history = useHistory();
    const ctx = useContext(GlobalContext);
    
    let [searchString, setSearchString] = useState('');
    
    
    const Search = () => {
        if (searchString !== '') {
            history.push(`${ctx.baseUrl}/search/${searchString}`);
            searchCallback();
        }
    };
    return (
        <div role="search" className={`flex-grow-1 ml-lg-auto`} id={`search`} style={{ maxWidth: '20rem'}}>
            <div className="input-group input-group-sm">
                <input className="form-control" type="text"
                    aria-label={`${ctx.translate('SEARCH')}.`}
                    placeholder={ctx.translate('SEARCH')}
                    value={searchString}
                    onChange={(ev) => setSearchString(ev.target.value)}
                    onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                            Search();
                        }
                    }}
                />
                <div className="input-group-append">
                    <button className="btn" style={{backgroundColor: style?.textColor ?? 'white', color: style?.backgroundColor ?? '#202833' }} aria-label={ctx.translate('SEARCH')}
                        type="button"
                        onClick={() => Search()}
                    >
                        <i className="fa fa-search"></i>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Search;
