import Cookies from 'universal-cookie';

export default class Storage {
    allowCookies: boolean;
    cookies: Cookies;

    constructor(allowCookies: boolean = false) {
        this.allowCookies = allowCookies;
        this.cookies = new Cookies();
    }

    setConsent(consent: boolean) {
        this.allowCookies = consent;
    }

    set(key: string, value: string, ignoreCookieConsent = false, setCookie = true) {
        if (ignoreCookieConsent || this.allowCookies) {
            const now = new Date();
            const expireTime = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());

            if (setCookie) {
                this.cookies.set(key, value, { path: '/', expires: expireTime });
            }
            try {
                if (value === undefined) {
                    localStorage.removeItem(key);
                } else {
                localStorage.setItem(key, value);
                }
            } catch (ex) {
                console.error("Exception from localStorage!", ex);
            }
        }
    }

    clearStorage()
    {
        localStorage.clear();
        let cookies = Object.keys(this.cookies.getAll());
        for (let x = 0; x < cookies.length; x++) {
            this.cookies.remove(cookies[x], { path: '/' });
        }
    }

    get(key: string, ignoreCookies = false) {
        let retValue;
        let cookieValue;

        if (!ignoreCookies) 
            cookieValue = this.cookies.get(key);

        if (cookieValue === undefined) {
            try {
                retValue = localStorage.getItem(key) ?? undefined;
            } catch (ex) {
                console.error("Exception from localStorage!", ex);
            }

            if (retValue !== undefined && !ignoreCookies) 
                this.set(key, retValue, true);
        } else {
            retValue = cookieValue;
        }

        return retValue;
    }
}