import React, { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import ShareIcons, { ShareIcon } from '../ShareIcons/ShareIcons';

export interface IFooterConf {
    content?: {
        icons?: ShareIcon[]
    }
    style?: {
        backgroundColor?: string,
        textColor?: string
    },
    enableRevokeCookieConsent?: boolean
}
interface IFooterProps {
    config?: IFooterConf
}

const Footer = ({ config }: IFooterProps) => {
    const ctx = useContext(GlobalContext);
    
    return (
        <div className="footer-wrapper" style={{ backgroundColor: config?.style?.backgroundColor ?? '#202833' }}>
            <div className="footer-content p-2" role="contentinfo">
                <div className="row w-100">
                    <a className="col-sm-8 offset-sm-2 col-6 offset-3 text-center" style={{ color: config?.style?.textColor ?? "#FFF" }} href="https://www.quickchannel.se/" target="_blank" rel="noopener noreferrer">
                        <span style={{ color: config?.style?.textColor ?? '#FFF' }} className="">Powered by Quickchannel</span>
                    </a>

                    <ShareIcons icons={config?.content?.icons ?? [ShareIcon.CopyLink, ShareIcon.Facebook]} wrapperClassName="col-sm-2 col-3 text-right" backgroundColor={config?.style?.textColor ?? 'white'} textColor={config?.style?.backgroundColor ?? 'black'} />

                    {config?.enableRevokeCookieConsent === true && ctx.cookiesAllowed ?
                        <button className="btn btn-link w-100" style={{ color: config?.style?.textColor ?? "#FFF", fontSize: '1rem' }} onClick={() => { ctx.revokeCookies(); }}>{ ctx.translate("Revoke cookie consent") }</button>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}

export default Footer;