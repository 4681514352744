import React, { useContext, useRef, useState, useCallback } from 'react';
import { Accordion, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { GlobalContext } from '../../context/GlobalContext';
import { ICategory } from '../ApiWrapper/ApiWrapper';

import Search from '../Search/Search';
import { ShareIcon } from '../ShareIcons/ShareIcons';

interface ILogo {
    src: string,
    alt: string,
    height: string
}


export interface IHeaderConf {
    content: {
        logo?: ILogo,
        // Setting moved to footer, but keep here for backward compatibility
        icons?: ShareIcon[]
    },
    style?: {
        backgroundColor?: string,
        textColor?: string
    }
}

interface IHeaderProps {
    config: IHeaderConf,
    categories: ICategory[]
}


const Header = ({ config, categories }: IHeaderProps) => {
    const ctx = useContext(GlobalContext);
    let history = useHistory();


    const [openedCategories, setOpenedCategories] = useState(false);
    const [openedMobileCategories, setOpenedMobileCategories] = useState(false);
    const [navbarMobileExpanded, setNavbarMobileExpanded] = useState(false);

    const categoryDropdown = useRef<HTMLDivElement | null>(null);
    const categoryAccordionToggle = useRef<HTMLDivElement | null>(null);
    const navbarMobileToggle = useRef<HTMLButtonElement | null>(null);

    const closeNavbarIfNeeded = useCallback(() => {
        if (navbarMobileExpanded) {
            navbarMobileToggle.current?.click();
        }
    }, [navbarMobileExpanded]);

    return (
        <div className="header-wrapper container-fluid" style={{ backgroundColor: config.style?.backgroundColor ?? '#202833' }}>
            <div className="header-content row" role="banner">

                <Navbar bg="" expand="lg" className='col-12' onToggle={(ev) => {
                    setNavbarMobileExpanded(ev)
                }} >
                    {config.content.logo !== undefined ?
                        <Navbar.Brand>
                            <h1>
                                <a aria-label={ctx.translate('GO-TO-STARTPAGE')} href={`${ctx.baseUrl === '' ? '/' : ctx.baseUrl}`} onClick={(ev) => { ev.preventDefault(); ev.stopPropagation(); history.push(`${ctx.baseUrl === '' ? '/' : ctx.baseUrl}`) }}>
                                    <img alt={config.content.logo.alt} height={config.content.logo.height} src={config.content.logo.src} />
                                </a>
                            </h1>
                        </Navbar.Brand>
                    : null}

                    <Navbar.Toggle ref={navbarMobileToggle} aria-controls="basic-navbar-nav">
                        <i className={`fa fa-1-5x ${navbarMobileExpanded ? 'fa-times' : 'fa-bars'} p-1`} style={{ color: config?.style?.textColor ?? 'white' }}></i>
                    </Navbar.Toggle>

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <Nav.Link className="px-2 h5 m-0 mr-lg-1 mb-1 mb-lg-0 font-weight-normal hover-underline ml-lg-2 ml-xl-4" style={{ color: config?.style?.textColor ?? 'white' }} href={`${ctx.baseUrl === '' ? '/' : ctx.baseUrl}`}
                                onClick={(ev: any) => {
                                    ev.preventDefault();
                                    ev.stopPropagation();
                                    history.push(`${ctx.baseUrl === '' ? '/' : ctx.baseUrl}`);
                                    closeNavbarIfNeeded();
                                }}>
                                {ctx.translate("START")}
                            </Nav.Link>


                            {categories.length > 0 ?
                                <>
                                    {/* Mobile accordion */}
                                    <Accordion onSelect={(ev) => { setOpenedMobileCategories(ev !== null); }} as="div" className="d-block d-lg-none nav-link" style={{ color: config?.style?.textColor ?? 'white', backgroundColor: config.style?.backgroundColor ?? '#202833' }}>
                                        <Accordion.Toggle ref={categoryAccordionToggle} as="div" className="py-2 h5 px-2 d-flex w-100 pointer font-weight-normal hover-underline" eventKey="0" role="button"
                                            tabIndex={0} aria-expanded={openedMobileCategories} aria-controls="mobile-accordion" onKeyPress={(ev?: React.KeyboardEvent<HTMLDivElement>) => {
                                                if (ev !== undefined) {
                                                    ev.preventDefault();
                                                    ev.stopPropagation();
                                                }
                                                if (categoryAccordionToggle.current !== null) {
                                                    categoryAccordionToggle.current.click();
                                                }
                                            }}
                                        >
                                            <span className={`dropdown-toggle ${openedMobileCategories ? 'flip-toggle' : ''}`}>{ctx.translate("CATEGORIES")}</span>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" id="mobile-accordion">
                                            <Accordion>
                                                {categories.map((x, ix) => {
                                                    if ((x.subCategories ?? []).length === 0) {
                                                        return <a key={ix} className="hover-underline dropdown-item" style={{ color: config?.style?.textColor ?? 'white', backgroundColor: config?.style?.backgroundColor ?? '#202833' }} href={`${ctx.baseUrl}/category/${x.label}`}
                                                            onClick={(ev) => {
                                                                ev.preventDefault();
                                                                ev.stopPropagation();
                                                                history.push(`${ctx.baseUrl}/category/${x.label}`);
                                                                categoryAccordionToggle.current?.click();
                                                                closeNavbarIfNeeded();
                                                            }}>
                                                            {x.label}
                                                        </a>
                                                    } else {
                                                        return <SubCategoryAccordion idPrefix={'mobile'} key={ix} category={x} style={{ color: config?.style?.textColor ?? 'white', backgroundColor: config?.style?.backgroundColor ?? '#202833' }} baseUrl={ctx.baseUrl} clickCallback={() => { categoryAccordionToggle.current?.click(); navbarMobileToggle.current?.click(); }} />
                                                    }
                                                })}
                                            </Accordion>

                                        </Accordion.Collapse>
                                    </Accordion>

                                    {[/* Regular dropdown */]}
                                    <Dropdown ref={categoryDropdown} navbar={true} onToggle={(ev) => { setOpenedCategories(ev); }} className="d-lg-block d-none">
                                        <Dropdown.Toggle as={Nav.Link} className={`h5 font-weight-normal m-0 hover-underline ${openedCategories ? 'flip-toggle' : ''}`} aria-controls="category-dropdown" style={{ color: config?.style?.textColor ?? 'white', backgroundColor: config?.style?.backgroundColor ?? '#202833' }} >
                                            {ctx.translate("CATEGORIES")}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ backgroundColor: config?.style?.backgroundColor ?? 'white' }} id="category-dropdown">
                                            {categories.map((x, ix) => {
                                                if ((x.subCategories ?? []).length === 0) {
                                                    return <Dropdown.Item key={ix} as={Link} to={`${ctx.baseUrl}/category/${x.label}`.toLocaleLowerCase()} className="hover-underline" style={{ backgroundColor: config?.style?.backgroundColor ?? '#202833', color: config?.style?.textColor ?? 'white' }}>{x.label}</Dropdown.Item>
                                                } else {
                                                    return <SubCategoryAccordion idPrefix={'dropdown'} key={ix} category={x} style={{ backgroundColor: config?.style?.backgroundColor ?? '#202833', color: config?.style?.textColor ?? 'white' }} baseUrl={ctx.baseUrl} clickCallback={() => { categoryDropdown.current?.click() }} />
                                                }
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>
                                : null}
                            {
                                ctx.producer === 'ability1493' ?
                                    <Nav.Link style={{ color: config?.style?.textColor ?? 'white' }} className="h5 font-weight-normal hover-underline px-2"
                                        target='_blank' rel="noreferrer" href='https://secure.quickchannel.com/qc/create/staticlive.asp?PRODUCER_ID=ability1493&SESSIONID=607CB2C40000000045F89E9B1DCEAE14&VMR=streaming@karkkila.fi'
                                    >
                                        Live
                                    </Nav.Link>
                                    : null
                            }
                            {
                                ctx.producer === 'ability1529' ?
                                <Nav.Link style={{ color: config?.style?.textColor ?? 'white' }} className="h5 font-weight-normal hover-underline px-2"
                                    target='_blank' rel="noreferrer" href='https://secure.quickchannel.com/qc/create/staticlive.asp?PRODUCER_ID=ability1529&SESSIONID=4A619FDD00315DFA18ADCB8E1FDCE3BD&VMR=streaming@kalajoki.fi'
                                >
                                    Live
                                </Nav.Link>
                                    : null
                            }
                        
                        </Nav>

                        <div className="ml-auto d-lg-flex d-block align-items-center flex-grow-1 px-2">
                            <Search style={config.style} searchCallback={closeNavbarIfNeeded} />
                            {
                                ctx.customerSettings.loginUrl !== "" && ctx.customerSettings.showInternalContent === true ?
                                    <div className="ml-xl-5 ml-lg-3 py-3">
                                        {ctx.auth !== undefined ?
                                            <a style={{ color: config?.style?.textColor ?? 'white' }} href={ctx.customerSettings.logoutUrl} rel="noreferrer" className="d-flex align-items-center"><i className="fa fa-user fa-1-5x mr-2"></i>{ctx.translate("Log out")}</a> :
                                            <a style={{ color: config?.style?.textColor ?? 'white' }} href={ctx.customerSettings.loginUrl} rel="noreferrer" className="d-flex align-items-center"><i className="fa fa-user fa-1-5x mr-2"></i>{ctx.translate("Log in")}</a>
                                        }
                                    </div>
                                    : null
                            }
                        </div>
                    </Navbar.Collapse>
                </Navbar>


            </div>
        </div >
    )
}

const SubCategoryAccordion = ({ idPrefix, category, style, baseUrl, clickCallback }: { idPrefix: string, category: ICategory, style: React.CSSProperties, baseUrl: string, clickCallback: () => void }) => {
    const [expanded, setExpanded] = useState(false);
    let history = useHistory();
    const toggle = useRef<HTMLDivElement | null>(null);

    return (
        <Accordion as={'div'} style={style} className="dropdown-item p-0" onSelect={(ev) => { setExpanded(ev !== null); }}>
            <Accordion.Toggle ref={toggle} eventKey='0' as={"div"} role="button" aria-expanded={expanded} aria-controls={`${idPrefix}-${category.label}-acc`} onKeyPress={(ev?: React.KeyboardEvent<HTMLDivElement>) => {
                if (ev !== undefined) {
                    ev.preventDefault();
                    ev.stopPropagation();
                }
                if (toggle.current !== null) {
                    toggle.current.click();
                }
            }} className={`hover-underline py-1 px-4 w-100 dropdown-toggle ${expanded ? 'flip-toggle' : ''}`} tabIndex={0}>{category.label}</Accordion.Toggle>
            <Accordion.Collapse eventKey='0' id={`${idPrefix}-${category.label}-acc`}>
                <div className="ml-3">
                    {(category.subCategories ?? []).map((subCategory, ix) => {
                        return <a key={ix} className="hover-underline dropdown-item" style={style} href={`${baseUrl}/category/${category.label}/${subCategory.label}`}
                            onClick={(ev) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                                history.push(`${baseUrl}/category/${category.label}/${subCategory.label}`);
                                toggle.current?.click();
                                clickCallback();
                            }}>
                            {subCategory.label}
                        </a>;
                    })}
                </div>
            </Accordion.Collapse>
        </Accordion>
    );
}

export default Header;