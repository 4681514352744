import sanitizeHtml from 'sanitize-html';
import { IAuth } from '../../context/GlobalContext';

export enum SortDirection {
    ASCENDING = 'ASC',
    DESCENDING = 'DESC'
}

export interface ApiFilter {
    query?: string,
    categories?: string[],
    producer_id: string
}

export const SortableColumns = [
    {
        value: 'time',
        label: '$$DATE'
    },
    {
        value: 'duration',
        label: '$$DURATION'
    }
]

export interface SortColumn {
    value: string,
    label: string,
    direction: SortDirection
}
export interface ApiSort {
    columns: SortColumn[]
}

export interface ProgramData {
    thumb: string,
    name: string,
    description: string,
    duration: number,
    encodedId: string,
    dualstream?: boolean,
    playerVersion?: number,
    time: Date,
    startPublish?: Date,
    categories: string[],
    language : string
    chapters : IChapter[],
    channel: ProgramType
}

export enum ProgramType {
    Vod = "Vod",
    Live = "Live"
}

export interface IChapter{
    name:string,
    offset:number,
    visible:boolean
}

export interface ICategory {
    label: string,
    subCategories?: ICategory[],
}

class ApiWrapper {

    API_BASE = document.location.href.includes("localhost") ? 'https://dev-srv1.quickchannel.com/api' : '/api';

    LEGACY_URL = (document.location.href.includes("dev") || document.location.href.includes("localhost")) ? 'https://dev-srv2.quickchannel.com' : 'https://secure.quickchannel.com';

    async getLiveAndUpcomingMovies (producer_id: string, offset: number, results: number, auth?: IAuth) {
        let queryParams: string[] = [];
        queryParams.push(`count=${results}`);
        queryParams.push(`offset=${offset}`);

        if (auth !== undefined) {
            queryParams.push(`sessionId=${auth.sessionId}`);
            queryParams.push(`producer_id=${auth.producerId}`);
        }

        const url = `${this.API_BASE}/programs/${producer_id}/liveandupcoming?${queryParams.join('&')}`;
        console.log("Requesting ", url);
        return fetch(url, {}).then(resp => resp.json()).then(resp => {
            console.log(resp);
            if (resp.success) {
                for (var x = 0; x < resp.data.length; x++) {
                    resp.data[x] = this.correctProperties(resp.data[x]);
                }
                return {
                    maxCount: resp.total ?? resp.count,
                    data: resp.data
                };
            } else {
                return null;
            }
        }).catch(err => {
            console.warn("Error from API: '", err, "'");
            return null;
        });
    }

    async getMovies (filter: ApiFilter, offset: number, results: number, sort: ApiSort, auth?: IAuth) {
        // Should be some kind of Fetch here...
        let endpoint;
        if (filter.query !== undefined) {
            endpoint = `search/${filter.query}`;
        } else {
            endpoint = 'play';
        }

        let queryParams = [];

        let reqBody: any = {};

        queryParams.push(`count=${results}`);
        queryParams.push(`offset=${offset}`);

        if (filter.categories !== undefined && filter.categories.length > 0) {
            reqBody.categories = filter.categories;
            reqBody.categories = reqBody.categories.map((x: string) => encodeURIComponent(x));
        }

        queryParams.push(`filter=${JSON.stringify(reqBody)}`);

        
        let reParsedSort = sort.columns.map((x) => { return `${x.direction === SortDirection.ASCENDING ? '+' : '-'}${x.value}`; });
        queryParams.push(`sort=${reParsedSort}`);
            
        if (auth !== undefined) {
            queryParams.push(`sessionId=${auth.sessionId}`);
            queryParams.push(`producer_id=${auth.producerId}`);
        }

        const url = `${this.API_BASE}/programs/${filter.producer_id}/${endpoint}?${queryParams.join('&')}`;
        console.log("Requesting ", url);
        return fetch(url, {}).then(resp => resp.json()).then(resp => {
            if (resp.success) {
                for (var x = 0; x < resp.data.length; x++) {
                    resp.data[x] = this.correctProperties(resp.data[x]);
                }
                return {
                    maxCount: resp.total ?? resp.count,
                    data: resp.data
                };
            } else {
                return null;
            }
        }).catch(err => {
            console.warn("Error from API: '", err, "'");
            return null;
        });
    }

    async getMovie(id: string, auth?: IAuth) : Promise<ProgramData | null> {
        let url = `${this.API_BASE}/programs/${id}/view`;

        if (auth !== undefined) {
            url = url + `?sessionid=${auth.sessionId}&producer_id=${auth.producerId}`;
        }
        return fetch(url).then(resp => resp.json()).then((resp) => {
            
            let data;
            if (resp.success) {
                if (resp.data.program !== undefined) {
                    data = resp.data.program;
                } else {
                    data = resp.data;
                }
            } else if (resp.errorCode === "ERROR_UNKNOWN_PROGRAM") {
                return null;
            } else {
                data = resp.data.programData;
            }
            
            let parsedData = this.correctProperties(data);
            
            let result = {
                encodedId: parsedData.encodedId,
                categories: parsedData.categories,
                name: parsedData.name,
                duration: parsedData.duration,
                description: parsedData.description,
                playerVersion: (parsedData.playerVersion === undefined) ? 0 : parsedData.playerVersion,
                thumb: parsedData.thumb,
                dualstream: (data.dualstream === "1"),
                time: parsedData.time,
                startPublish: parsedData.startPublish,
                language: parsedData.language,
                chapters: data.chapters,
                channel: data.channel
            };
            
            return result;
        }).catch(err => {
            console.warn("Error: ", err);
            return null;
        });
    }

    async getCategories(producer: string, auth?: IAuth): Promise<ICategory[]> {
        let url = `${this.API_BASE}/categories/${producer}/used`;
        if (auth !== undefined) {
            url = url + `?sessionid=${auth.sessionId}&producer_id=${auth.producerId}`;
        }

        return fetch(url).then(res => res.json()).then(resp => {
            if (resp.success) {
                return resp.data;
            } else {
                console.warn("Failed to load categories", resp);
                return [];
            }
        })
    }

    correctProperties = (inp: any): ProgramData => {
        if (inp.thumb.indexOf('http') === -1) {
            inp.thumb = `${this.LEGACY_URL}${inp.thumb}`;
        }

        try {
            inp.time = new Date(inp.time);
    
            if (inp.startPublish !== undefined) {
                inp.startPublish = new Date(inp.startPublish);
            }
        } catch (ex) {
            console.warn("Failed to parse a date", ex);
        }
        
        inp.description = decodeURIComponent((inp.description ?? "").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
        inp.description = sanitizeHtml(inp.description);

        return (inp as ProgramData);
    }
}

const API = new ApiWrapper();

export default API;
