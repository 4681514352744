import React from "react";
import { IMainStyle } from "../comp/CustomRouter/CustomRouter";
import { IFilmcardStyle } from "../comp/FilmCard/FilmCard";
type ExploreLanguage = typeof import('../submodules/localization/quickchannel-play/en.json');

export interface ICustomerSettings {
    showInternalContent: boolean,
    showExternalContent: boolean,
    loginUrl: string,
    logoutUrl: string
}

export interface IAuth {
    sessionId: string,
    producerId: string
}

type ContextProps = {
    translate: (key: keyof ExploreLanguage) => string,
    producer: string,
    baseUrl: string,
    mainStyle?: IMainStyle,
    cardStyle?: IFilmcardStyle,
    cookiesAllowed: boolean,
    rssEnabled: boolean,
    revokeCookies: () => void,
    customerSettings: ICustomerSettings,
    auth?: IAuth
}

export const GlobalContext = React.createContext<ContextProps>({
    translate: (key: string) => key,
    producer: '',
    baseUrl: '',
    mainStyle: { backgroundColor: 'white', textColor: '#202833'},
    cardStyle: { highlighted: { textColor: '#202833', backgroundColor: 'white' }},
    cookiesAllowed: false,
    rssEnabled: false,
    revokeCookies: () => { },
    customerSettings: { showExternalContent: true, showInternalContent: false, loginUrl: "", logoutUrl: "" }
});